import React, { useEffect, useState } from "react";
import { getData } from "../../services/Api";
import { useNavigate } from "react-router-dom";

function Index() {
  const [showSearchResult, setShowSearchResult] = useState();
  const [searchList, setSearchList] = useState([]);

  const fetchSearchResult = async (val) => {
    try {
      const apiData = await getData(`partner-search?search_text=${val}`);
      setSearchList(apiData?.data?.data || []);
    } catch (error) {}
  };

  const handleSearch = (e) => {
    if (e.target.value !== "") {
      setShowSearchResult(true);
    } else {
      setShowSearchResult(false);
    }
    fetchSearchResult(e?.target?.value);
  };
  const navigate = useNavigate();
  return (
    <div className="sm:px-[0] px-[30px] blueGradient text-center py-[100px]">
      <h1 className="mainHeading mb-3 bannerTitle">LeadSquared Partner Directory</h1>
      <p className="description subtitle">
      Thrive within the Leadsquared Ecosystem – find partners for implementation, strategy, and growth.
      </p>
      <div className="w-full sm:w-[450px] mx-auto mt-[50px] ">
        <div className="relative w-full">
          <input
            className="border border-primary border-solid bg-white py-3 pl-2 w-full rounded-[4px]"
            type="text"
            placeholder="Search partners..."
            onKeyUp={handleSearch}
          />
          <button
            className="rounded-[4px] btnPrimary absolute right-0 top-0 h-[100%] w-[100px] text-center"
            type="button"
            onClick={
              showSearchResult ? () => setShowSearchResult(false) : () => {}
            }>
            {showSearchResult ? (
              <img src="/images/close.svg" className="mx-auto" />
            ) : (
              <img src="/images/searchIcon.svg" className="mx-auto" />
            )}
          </button>
        </div>
        {showSearchResult && (
          <ul className="mt-1 border rounded-[4px] border-[#C8DCEC] text-left px-3 py-2 text-[16px] text-[#6C6F75] customScroll max-h-[140px] overflow-y-auto">
            {searchList?.length > 0 ? (
              <>
                {searchList?.map((el) => (
                  <li
                    className="mb-2 cursor-pointer"
                    onClick={() => navigate(`/partner/${(el?.title)?.toLowerCase().trim().replace(' ','_')}`)}>
                    {el?.title}
                  </li>
                ))}
              </>
            ) : (
              <li className="mb-0 opacity-[0.6]">No records found</li>
            )}
          </ul>
        )}
      </div>
    </div>
  );
}

export default Index;
